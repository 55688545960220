import { render, staticRenderFns } from "./credentials.vue?vue&type=template&id=84550268&scoped=true"
import script from "./credentials.vue?vue&type=script&lang=js"
export * from "./credentials.vue?vue&type=script&lang=js"
import style0 from "./credentials.vue?vue&type=style&index=0&id=84550268&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84550268",
  null
  
)

export default component.exports