<template>
  <div class="credentials__layout">
    <div class="credentials__title">
      <h3>
        {{ $t('Credentials') }}
      </h3>
    </div>
    <div class="credentials__content">
      <!-- TODO: FE#5880 Enable back when adding credentials swap -->
      <CyAlert
        v-if="false"
        :title="$t('cloudCostManagement.readonlyContent.title')"
        :content="$t('cloudCostManagement.readonlyContent.message')"/>

      <CyAlert
        v-has-rights-to="'UpdateCloudCostManagementAccount'"
        data-cy="ccm-readonly"
        :title="$t('cloudCostManagement.readonlyContent.title')"
        :content="$t('readonlyContentMessage')"/>

      <CyCredentials
        v-has-rights-to="'ListCredentials'"
        :value="credentialCanonical"
        class="required-field"
        required
        :disabled="disabled"
        :error-messages="credentialsErrors"
        :icon-size="20"
        :items="filteredCredentials"
        :label="$t('cloudCostManagement.providerCredential')"
        data-cy="ccm-credential"
        @change="$emit('change', $event)"/>

      <CyCloudCostManagementFormsCredentials
        swap-mode
        :hide-details="!$cycloid.permissions.canDisplay('ListCredentials')"
        :credential="accountCredential"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import CyCloudCostManagementFormsCredentials from '@/components/cloud-cost-management/forms/credentials.vue'
import CyCredentials from '@/components/credentials.vue'
import { checksPass } from '@/utils/helpers'
import { getCredentialType, hasCloudCostManagementEB } from '@/utils/helpers/cloud-cost-management'

export default {
  name: 'CyCloudCostManagementAccountsCredentials',
  components: {
    CyCloudCostManagementFormsCredentials,
    CyCredentials,
  },
  props: {
    cloudProvider: {
      type: Object,
      default: () => ({}),
    },
    credentialCanonical: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    accountCredential: {},
  }),
  computed: {
    ...mapState('organization', {
      credentials: (state) => state.available.credentials,
      credentialsErrors: (state) => state.errors.credentials,
    }),
    ...mapState('organization/credential', {
      credential: (state) => state.detail,
    }),
    filteredCredentials () {
      const filteredCredentials = _.chain(this.credentials)
        .filter(['type', this.credential?.type])
        .filter(({ in_use: inUse }) => !hasCloudCostManagementEB(inUse.external_backends))
        .value()
      return [...filteredCredentials, this.accountCredential]
    },
  },
  watch: {
    credentialCanonical: {
      handler (credentialCanonical) {
        const canGetCredential = checksPass({
          canDisplayCredential: this.$cycloid.permissions.canDisplay('GetCredential', credentialCanonical),
          canListCredential: this.$cycloid.permissions.canDisplay('ListCredentials'),
        })

        if (canGetCredential) this.GET_CREDENTIAL({ credentialCanonical })
      },
    },
  },
  async mounted () {
    this.accountCredential = {
      path: this.credentialCanonical,
      type: getCredentialType(this.cloudProvider?.canonical),
    }

    // overwrites accountCredential from credential, if user has access
    if (this.$cycloid.permissions.canDisplay('ListCredentials')) {
      await this.FETCH_AVAILABLE({ keyPath: 'credentials' })
      this.accountCredential = _.cloneDeep(this.credential)
    }
  },
  destroyed () {
    this.RESET_CREDENTIAL_STATE()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/credential', [
      'GET_CREDENTIAL',
    ]),
    ...mapMutations('organization/credential', [
      'RESET_CREDENTIAL_STATE',
    ]),
  },
  i18n: {
    messages: {
      en: {
        readonlyContentMessage: 'Credentials swap not available yet.',
      },
      es: {
        readonlyContentMessage: 'El intercambio de credenciales aún no está disponible.',
      },
      fr: {
        readonlyContentMessage: `L'échange d'identifiants n'est pas encore disponible.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-credential ::v-deep {
  .v-select__selections > div {
    justify-content: flex-start;
  }

  .credential-icon {
    width: 25px;
  }
}

.credentials {
  &__layout {
    display: flex;
  }

  &__title {
    width: 315px;
  }

  &__content {
    width: 490px;
  }
}
</style>
