<template>
  <div class="layout__container d-flex">
    <div
      v-if="!account || loading"
      class="layout__content--loading">
      <v-progress-circular
        class="loading-spinner"
        indeterminate
        size="100"
        color="secondary"/>
    </div>
    <div
      v-else
      class="layout__content">
      <div class="layout__main-content">
        <div class="layout__main-column">
          <CyCloudCostManagementAccountsCredentials
            :cloud-provider="_.get(accountData, 'cloud_provider')"
            :credential-canonical="credentialCanonical"
            :disabled="isCredentialsFormDisabled"
            @change="$set(accountData.external_backend, 'credential_canonical', $event)"/>
          <v-divider class="my-8"/>
          <CyCloudCostManagementAccountsConfiguration
            v-if="!isLinkedAccount"
            :account="accountData"
            :disabled="saving || accountData.status === 'import'"
            @is-valid="validConfiguration = $event"
            @change="$set(accountData.external_backend.configuration, $event.key, $event.data)"/>
          <v-divider
            v-if="!isLinkedAccount"
            v-has-rights-to="'DeleteCloudCostManagementAccount'"
            class="my-8"/>
          <CyAlert
            class="mt-6 mb-4"
            theme="error"
            closeable
            :content="accountErrors"
            @close="CLEAR_CE_ERRORS('account')"/>
          <CyDangerZone
            v-has-rights-to="'DeleteCloudCostManagementAccount'"
            :disabled="saving"
            :disable-value="!isLinkedAccount ? !account.enabled : null"
            :delete-button-text="$t('deleteButtonText')"
            :delete-text="$t('deleteText')"
            :enable-text="$t('enableText')"
            :entity-type="$t('account.account')"
            :entity-name="account.account_id"
            class="d-flex"
            @change-disabled="$set(accountData, 'enabled', $event)"
            @delete="onDeleteHandler"/>
        </div>
        <div class="layout__sidebar pa-8">
          <CyCloudCostManagementAccountsSummary
            v-if="accountCredential"
            :account-credential="accountCredential"/>
        </div>
      </div>
      <div v-has-rights-to="'UpdateCloudCostManagementAccount'">
        <v-divider class="actions-border mt-8 mx-n8"/>
        <div class="layout__actions py-4 mx-n8 mb-n8 white">
          <CyButton
            variant="secondary"
            theme="primary"
            icon="close"
            :disabled="saving"
            @click="() => $router.push({ name: 'cloudCostManagementAccounts' })">
            {{ $t('forms.btnCancel') }}
          </CyButton>
          <CyButton
            class="ml-5"
            :disabled="!validConfiguration || !$hasDataChanged('accountData')"
            :loading="saving"
            theme="success"
            icon="check"
            @click="saveChanges">
            {{ $t('forms.btnSave') }}
          </CyButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import CyCloudCostManagementAccountsConfiguration from '@/components/cloud-cost-management/accounts/configuration.vue'
import CyCloudCostManagementAccountsCredentials from '@/components/cloud-cost-management/accounts/credentials.vue'
import CyCloudCostManagementAccountsSummary from '@/components/cloud-cost-management/accounts/summary.vue'
import CyDangerZone from '@/components/danger-zone.vue'
import { checksPass, constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageCloudCostManagementAccountsAccount',
  components: {
    CyCloudCostManagementAccountsCredentials,
    CyCloudCostManagementAccountsSummary,
    CyCloudCostManagementAccountsConfiguration,
    CyDangerZone,
  },
  breadcrumb () {
    const accountId = this.accountData?.account_id
    return constructBreadcrumb(this.$options.name, accountId, [
      {
        name: 'cloudCostManagementAccounts',
        label: this.$t('routes.cloudCostManagementAccounts'),
      },
      {
        name: 'cloudCostManagementSection',
        label: this.$t('routes.cloudCostManagementSection'),
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.cloudCostManagementAccountsAccount'),
    }
  },
  data: () => ({
    accountData: {
      external_backend: {
        configuration: {},
      },
    },
    disableCredentialSwap: true,
    saving: false,
    loading: true,
    validConfiguration: false,
  }),
  computed: {
    ...mapState('organization/cloudCostManagement', {
      account: (state) => state.account,
      accountErrors: (state) => state.errors.account,
    }),
    ...mapState('organization/credential', {
      credential: (state) => state.detail,
    }),
    ...mapGetters('organization/cloudCostManagement', [
      'isLinkedAccount',
    ]),
    accountCredential () {
      const { name, type } = this.credential || {}
      const canonical = this.credentialCanonical
      const cloudProviderCanonical = this.accountData.cloud_provider?.canonical

      return { canonical, name, type, cloudProviderCanonical }
    },
    credentialCanonical () {
      if (_.isEmpty(this.accountData)) return

      const { canonical: credentialCanonical } = this.credential || {}
      const { credential_canonical: ebCredentialCanonical } = this.accountData?.external_backend || {}

      return this.isLinkedAccount && credentialCanonical
        ? credentialCanonical
        : ebCredentialCanonical
    },
    isCredentialsFormDisabled () {
      const { disableCredentialSwap, saving, accountData } = this
      const hasPermissions = this.$cycloid.permissions.canDisplay('UpdateCloudCostManagementAccount')

      return disableCredentialSwap || saving || accountData.status === 'import' || !hasPermissions
    },
  },
  async mounted () {
    await this.getAccount()
    await this.getCredential()

    this.loading = false
    this.$setOriginalData()
  },
  destroyed () {
    this.RESET_CE_STATE('account')
  },
  methods: {
    ...mapActions('organization/credential', [
      'GET_CREDENTIAL',
    ]),
    ...mapActions('organization/cloudCostManagement', [
      'GET_ACCOUNT',
      'UPDATE_ACCOUNT',
      'DELETE_ACCOUNT',
    ]),
    ...mapMutations('organization/cloudCostManagement', [
      'CLEAR_CE_ERRORS',
      'RESET_CE_STATE',
    ]),
    async getAccount () {
      const { accountCanonical } = this.$route.params
      await this.GET_ACCOUNT({ accountCanonical })
      this.accountData = _.cloneDeep(this.account)
    },
    async getCredential () {
      const { credentialCanonical } = this
      const canGetCredential = checksPass({
        canDisplayCredential: this.$cycloid.permissions.canDisplay('GetCredential', credentialCanonical),
        canListCredential: this.$cycloid.permissions.canDisplay('ListCredentials'),
      })

      if (canGetCredential) {
        await this.GET_CREDENTIAL({ credentialCanonical })
      }
    },
    async saveChanges () {
      this.$toggle.saving(true)
      const { $router, accountData } = this
      const account = _.pick(accountData, ['enabled', 'external_backend'])
      await this.UPDATE_ACCOUNT({ $router, account, accountCanonical: accountData.canonical })
      this.$toggle.saving(false)
    },
    async onDeleteHandler () {
      this.$toggle.loading(true)
      const { $router, accountData: { canonical: accountCanonical } } = this
      await this.DELETE_ACCOUNT({ $router, accountCanonical })
      this.$toggle.loading(false)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.cloudCostManagementAccounts',
        enableText: 'Make it part of your Cloud Cost Management again. Cycloid will have to import data from the day the account was disabled only.',
        deleteButtonText: 'Delete account',
        deleteText: 'Irreversible action (credentials stay on the system)',
      },
      es: {
        title: '@:routes.cloudCostManagementAccounts',
        enableText: 'Vuelva a integrarlo otra vez su a Cloud Cost Management. Cycloid solo tendrá que importar datos desde el día en que se desactivó la cuenta.',
        deleteButtonText: 'Borrar cuenta',
        deleteText: 'Acción irreversible (las credenciales permanecen en el sistema)',
      },
      fr: {
        title: '@:routes.cloudCostManagementAccounts',
        enableText: 'Intégrez-le à nouveau à votre Cloud Cost Management. Cycloid devra importer les données à partir du jour où le compte a été désactivé uniquement.',
        deleteButtonText: 'Supprimer le compte',
        deleteText: 'Action irréversible (les identifiants seront préservés)',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-alert {
  width: 488px;
  margin-left: 315px;
}

::v-deep .danger-zone {
  &__title {
    width: 315px;
  }

  &__content {
    width: 490px;
  }
}

.layout {
  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 4px;
  }

  &__content {
    flex-direction: column;
  }

  &__content,
  &__main-content {
    display: flex;
    flex-grow: 1;

    &--loading {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
    }
  }

  &__main-column {
    flex-grow: 1;
    max-width: 1219px;
  }

  &__sidebar {
    width: 329px;
    padding-right: 18px;
    padding-left: 18px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
  }
}

.actions-border {
  max-width: unset;
}
</style>
